.toast {
	opacity: 1 !important;
}

.error{

	color: #c73939;
}

.msg-error {

	display: none;
}

.error .msg-error{

	display: block;
}

.error input, .error textarea, .error select {

	border-color: #c73939;
}

.container-fluid{

	padding-top: 15px;
}

.margin-left-2{

	margin-left: 2px;
}

.margin-left-5{

	margin-left: 5px;
}

.margin-left-10{

	margin-left: 10px;
}

.no-padding{

	padding: 0px;
}

.no-margin{

	margin: 0px;
}

.padding-2{

	padding: 2px
}

.nao-pular-linha{

	white-space: nowrap;
}

.green-background{

	background-color: #00cc99;
}

.red-background{

	background-color: #FF3B3B;
}

.row-vertical-center
{
	height: 100%;
	display: table-row;
}
.vertical-center
{
	display: table-cell;
	float: none;
	vertical-align: middle;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
	 background: white !important;
}