@font-face {
    font-family: 'password';
    src: url('../../fonts/password.woff2') format('woff2'),
    url('../../fonts/password.woff') format('woff'),
    url('../../fonts/password.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.fake-password{

    font-family: 'password';
    font-size: 11px;
}


.fake-password::-webkit-input-placeholder {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
}

.fake-password:-ms-input-placeholder {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
}

.fake-password:-moz-placeholder {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
}

.fake-password::-moz-placeholder {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
}
